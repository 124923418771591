<template>
  <div class="relative">
    <div style="background: rgba(48,137,113,0.1);">
      <div
        class=" grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] ">
        <div class="col-span-23 col-start-2">
          <div class="pt-30 flex flex-row space-x-[85px]">
            <div>
              <section>
                <div>
                  <h1 style="color: #308971;">Contact</h1>
                </div>
                <div class="mt-22 flex flex-col "><a target="_self"
                    class="text-iblack-500 hover:opacity-70 focus:opacity-70 transition-opacity duration-300"
                    href="/">Careers</a><a target="_self"
                    class="text-iblack-500 hover:opacity-70 focus:opacity-70 transition-opacity duration-300"
                    href="/">Email</a></div>
            </section>
          </div>
          <div>
            <section>
              <div>
                <h1 style="color: #308971;">Legal</h1>
              </div>
              <div class="mt-22 flex flex-col "><a target="_self"
                  class="text-iblack-500 hover:opacity-70 focus:opacity-70 transition-opacity duration-300"
                  href="/">Privacy Policies</a><a target="_self"
                  class="text-iblack-500 hover:opacity-70 focus:opacity-70 transition-opacity duration-300"
                  href="/">Terms of Use</a><a target="_self"
                  class="text-iblack-500 hover:opacity-70 focus:opacity-70 transition-opacity duration-300"
                  href="/">Form CRS</a><button
                  class="text-iblack-500 text-left hover:opacity-70 focus:opacity-70 transition-opacity duration-300">Cookies</button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid pt-6 pb-5">
      <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block ">
        <div role="separator" aria-orientation="horizontal" class="w-full h-full block"
          style="background-color: rgb(155, 155, 155); opacity: 1; transform: none;"></div>
      </div>
    </div>
    <div class="main-grid">
      <div class="main-grid--in-grid md:col-span-18 md:col-start-2 pb-5 row-start-1 flex flex-col md:flex-row ">
        <span
          class="text-iblack-500 text-sm whitespace-pre-wrap">
          Nothing on this website is intended as, or constitutes,
          marketing of any fund in the United Kingdom or any member state of the European Economic Area within the
          meaning of the Directive 2011/61/EU on Alternative Investment Fund Managers.<br/><br/><br/>

          None of the information on this website constitutes a financial promotion within the meaning of section 21 of
          the UK Financial Service and Markets Act 2000 and, in any event, such information is exclusively intended for,
          and directed only at, persons who meet the definition of a professional client under Article 2(1)(8) of
          Regulation (EU) No 600/2014 on markets in financial instruments as it forms part of the laws of England and
          Wales by virtue of the European Union (Withdrawal) Act 2018 and as amended, and who are: (i) Investment
          Professionals within the meaning of Article 19 of the Financial Services and Markets Act 2000 (Financial
          Promotion) Order 2005 (the “FPO”); (ii) High Net Worth Companies and certain other entities falling within
          Article 49 of the FPO; or (iii) any other persons to whom such a financial promotion may be lawfully made.<br/><br/><br/>

          Assets under management are calculated using net asset value plus uncalled capital commitments of all ZEN CAPITAL
          and IPI Partners, LLC accounts and funds as of a recent date. Such figures are approximate, unaudited and
          subject to change</span></div>
      <div
        class="col-span-5 flex md:justify-end col-start-2 pb-5 row-start-2 md:row-start-1 md:col-start-20 md:text-right">
        <span class="text-iblack-500 text-sm">© 2022 ZEN CAPITAL Capital, LLC</span></div>
    </div>
  </div>
</div></template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>
